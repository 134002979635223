.wrapperx {
    position: fixed;
    left: 50%;
    bottom: -10em;
    height: 20em;
    width: 20em;
    margin-left: -10em;
    border-radius: 50%;
    background-color: #f0a0a0;
    overflow:hidden;
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    z-index: 99998;
}

.nav .button {
    border: none;
    color: #dcc;
    text-align: center;
    font-size: 1.8em;
    height: 3em;
    width: 3em;
    background-color: #f05860;
    bottom: -1.5em;
    position: fixed;
    left: 50%;
    margin-left: -1.5em;
    border-radius: 50%;
    cursor: pointer;
    z-index: 99999;
}

.wrapperx li {
    position: absolute;
    list-style: none;
    font-family: Helvetica, sans-serif;
    height: 10em;
    width: 10em;
    background-color: #7c9;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    overflow: hidden;
    left: 50%;
    top: 50%;
    margin-left: -10em;
    margin-top: -10em;
    -webkit-transition: border .3s ease;
    -moz-transition: border .3s ease;
    transition: border .3s ease;
}
.wrapperx.open {
    border-radius: 50%;
    pointer-events: auto;
    -webkit-transform: scale(1);
}

.wrapperx li a{
    display: block;
    font-size: 1.0em;
    height: 16em;
    width: 16em;
    position:absolute;
    bottom: -8em;
    right: -8em;
    border-radious: 50%;
    text-decoration: none;
    color: #fff;
    padding-top: 1.8em;
    text-align: center;
    -webkit-transform: skew(-54deg) rotate(-76deg);
    -moz-transform: skew(-54deg) rotate(-76deg);
}

.wrapperx li:first-child {
    -webkit-transform: skew(50deg);
    -moz-transform: skew(50deg);
    background-color: #4593e3;
}

.wrapperx li:nth-child(2) {
    -webkit-transform: rotate(36deg) skew(54deg);
    -moz-transform: rotate(36deg) skew(54deg);
    background-color: #0e83cd;
}

.wrapperx li:nth-child(3) {
    -webkit-transform: rotate(72deg) skew(54deg);
    -moz-transform: rotate(72deg) skew(54deg);
    background-color: #4593e3;
}

.wrapperx li:nth-child(4) {
    -webkit-transform: rotate(108deg) skew(54deg);
    -moz-transform: rotate(108deg) skew(54deg);
    background-color: #0e83cd;
}

.wrapperx li:nth-child(5) {
    -webkit-transform: rotate(144deg) skew(54deg);
    -moz-transform: rotate(144deg) skew(54deg);
    background-color: #4593e3;
}

.wrapperx li:hover {
    background-color: #f05860;
}
.login_kutu{
    font-size: 20px;
    border: 1px #3689d0 solid;
    color:#3689d0;
    background-color:white;
    width: 19%;
    height: 240px;
    border-radius: 5px;
    padding-top: 22px;
    margin-top: 15px;
    margin-left: 4.2px;
    margin-right: 4.2px;
    text-align: center;
    cursor: pointer;
}
.login_kutu ul{font-size: 14px;color:grey;text-align: left;padding-left: 15px}
.login_kutu i{font-size: 30px}
.login_kutu:hover{
    background-color:#3689d0;
    color:white;
}
.login_kutu:hover ul {
    color: white;
}

.subs_kutu{
    font-size: 12px;
    border: 1px #3689d0 solid;
    color:#3689d0;
    background-color:white;
    width: 32.6%;
    height: 240px;
    border-radius: 5px;
    padding-top: 22px;
    margin-top: 15px;
    margin-left: 4.2px;
    margin-right: 4.2px;
    text-align: center;
    cursor: pointer;
}
.subs_kutu i{font-size: 30px}
.subs_kutu h3{font-size: 17px;font-weight: bold;}
.subs_kutu p{font-size: 14px;color:grey}
.subs_kutu .sec{margin-top: 10px}
.subs_kutu_active{background-color: #3689d0;color:white}
.subs_kutu_active h2 span{color:white !important;}