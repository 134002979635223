.arrow {
  position: fixed;
  top: 30px;
  left: 430px;
  width: 120px;
  height: 9%;
}

.arrow__body {
  width: 100%;
  height: 95%;
  margin-left: 11px;
  border-width: 5px 0 0 5px;
  border-style: solid;
  border-color: darkred;
  border-top-left-radius: 100%;
}

.arrow__body::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-width: 20px 15px 0;
  border-style: solid;
  border-color: darkred transparent transparent;
}
