.supportWidget {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  border-top: 2px;
  border-radius: 10px;
  overflow-y: initial !important;
  overflow: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.modalBody {
  overflow-y: auto !important;
  height: 475px;
  padding: 0.571429rem 1rem;
  max-height: 70vh;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}

.supportWidget .close {
  opacity: 1;
}
