.input-icon-vertical {
  position: relative;
  left: 0;
}

.input-icon-vertical > i {
  color: #ccc;
  display: block;
  position: absolute;
  margin: 61px 2px 4px 10px;
  z-index: 3;
  width: 16px;
  font-size: 16px;
  text-align: center;
  left: 0;
}
