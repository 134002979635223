.react-datepicker-popper {
  z-index: 999999999999;
}

.react-datepicker-wrapper,
.react-datepicker-wrapper .react-datepicker__input-container {
  /* https://github.com/Hacker0x01/react-datepicker/issues/1672 */

  /* to make it exactly the same height as the bootstrap input-group-addons */
  display: block;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.7rem;
  line-height: 2.7rem;
  font-size: 1.1rem;
}

.react-datepicker__today-button {
  font-size: 1.1rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  display: none;
}

.date.form-group .input-group-addon {
  cursor: pointer;
}

.date.form-group .input-group-addon:hover {
  opacity: 0.8;
}

.react-datepicker__header__dropdown,
.react-datepicker__header__dropdown--scroll {
  font-size: 1.9em;
  margin: -4px;
}
